import React from 'react'
import PropTypes from 'prop-types'
import StyledButtonGroup from './style'

const ButtonGroup = props => {
  const { children } = props
  return <StyledButtonGroup {...props}>{children}</StyledButtonGroup>
}

ButtonGroup.propTypes = {
  children: PropTypes.any,
}

export { ButtonGroup }
