import React from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'

import { MapComposition } from '../../components/MapComposition'

export default ({ data, pageContext }) => {
  const page = data.wordpress.page
  const { dealers } = pageContext.data
  const {
    seo,
    acfPageTemplateDealersMap: { dealerListPageLink },
  } = page

  return (
    <Layout hideFooter>
      <SEO {...parseSeo(seo)} />
      <MapComposition
        mapHeaderLink={dealerListPageLink}
        dealers={dealers.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
        acfPageTemplateDealersMap {
          dealerListPageLink {
            title
            url
          }
        }
      }
    }
  }
`
