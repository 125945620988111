import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StyledAutoComplete from './style'

const AutoComplete = props => {
  const {
    matchingObjects,
    searchString,
    autoCompleteCursor,
    onSelect,
    onSoftSelect,
    innerRef,
  } = props

  const [firstRun, setFirstRun] = useState(true)
  const [innerCursor, setInnerCursor] = useState(null)

  useEffect(() => {
    if (matchingObjects.length > 0 && !firstRun) {
      setInnerCursor(autoCompleteCursor)
      if (autoCompleteCursor !== null) {
        onSoftSelect(matchingObjects[autoCompleteCursor].name)
      }
    }
    setFirstRun(false)
  }, [autoCompleteCursor])

  return matchingObjects.length > 0 && searchString.length > 0 ? (
    <StyledAutoComplete ref={innerRef}>
      {matchingObjects.map((obj, index) => {
        let startPos = obj.name
          .toLowerCase()
          .indexOf(searchString.toLowerCase())
        let endPos = startPos + searchString.length

        let first = obj.name.substring(0, startPos)
        let toHiglight = obj.name.substring(startPos, endPos)
        let last = obj.name.substring(endPos, obj.name.length)

        let result =
          obj.text !== null
            ? `
              <div><p>${first}<span>${toHiglight}</span>${last}</p></div>
              <span>${obj.text}</span>
            `
            : `<div><p>${first}<span>${toHiglight}</span>${last}</p></div>`

        console.log(result)
        return (
          <StyledAutoComplete.Button
            key={index}
            dangerouslySetInnerHTML={{ __html: result }}
            onClick={() => {
              onSelect(obj.name)
            }}
            onMouseEnter={() => {
              setInnerCursor(index)
            }}
            active={innerCursor !== null && innerCursor === index}
          />
        )
      })}
    </StyledAutoComplete>
  ) : null
}

AutoComplete.propTypes = {
  matchingObjects: PropTypes.array,
  searchString: PropTypes.string,
  autoCompleteCursor: PropTypes.number,
  onSelect: PropTypes.func,
  onSoftSelect: PropTypes.func,
  innerRef: PropTypes.any,
}

export { AutoComplete }
