import { getCurrentLang } from './lang'
import { getDistance } from './math'

const sortDealersByDistance = (dealers, userPosition) => {
  return dealers
    .map(dealer => getDealerWithDistance(dealer, userPosition))
    .sort(sortByDistance)
}

const sortDealersByRank = dealers => {
  return dealers.sort(sortByRank)
}

const getDealerWithDistance = (dealer, userPosition) => {
  const {
    acfAttributes: { dealerLatitude, dealerLongitude, dealerPartner },
  } = dealer

  const distance =
    dealerLatitude && dealerLongitude && userPosition
      ? getDistance(
          userPosition.latitude,
          userPosition.longitude,
          dealerLatitude,
          dealerLongitude,
          'K'
        )
      : null

  // If a partner is within 100 km they should be sorted first.
  // By subtracting 100 km it will get a negative value and hence
  // always be less than the other distances. We keep `distance` to
  // display the actual distance in UI.
  const promotionRangeKm = 100
  const sortDistance =
    dealerPartner && distance !== null && distance < promotionRangeKm
      ? distance - promotionRangeKm
      : distance

  return { ...dealer, distance, sortDistance }
}

const sortByRank = (dealerA, dealerB) =>
  compareDealer(
    dealerA.acfAttributes.dealerRank,
    dealerB.acfAttributes.dealerRank,
    true
  )

const sortByDistance = (dealerA, dealerB) =>
  compareDealer(dealerA.sortDistance, dealerB.sortDistance, false)

const compareDealer = (a, b, sortDescending) => {
  // equal items sort equally
  if (a === b) {
    return 0
  }
  // nulls sort after anything else
  else if (a === null) {
    return 1
  } else if (b === null) {
    return -1
  } else if (sortDescending) {
    return a < b ? 1 : -1
  } else {
    return a < b ? -1 : 1
  }
}

const mapKlarnaPickupPoint = pickupPoint => {
  const id = pickupPoint.databaseId
  const city = pickupPoint.acfAttributes.dealerCity
  const description =
    getCurrentLang() === 'sv'
      ? 'Skickas från vårt lager till vald butik inom 1-3 dagar, om varan finns i lager. Se leveransinfo på produktsidan.'
      : ''

  const klarnaPickupPoint = {
    id: id,
    name: `${city} ${pickupPoint.title}`,
    description: description,
    price: 0,
    tax_amount: 0,
    tax_rate: 2500,
    shipping_method: 'PickUpPoint',
    delivery_details: {
      pickup_location: {
        id: id,
        name: pickupPoint.title,
        address: {
          street_address: pickupPoint.acfAttributes.dealerAddress,
          postal_code: pickupPoint.acfAttributes.dealerZip,
          city: city,
        },
      },
    },
  }

  return klarnaPickupPoint
}

export { sortDealersByDistance, sortDealersByRank, mapKlarnaPickupPoint }
