import {
  AppContext,
  setDealerFilterString,
  setSelectedDealerId,
  toggleDealerFlag,
} from '../../config/context'
import React, { useContext, useState } from 'react'
import { sortDealersByDistance, sortDealersByRank } from '../../helpers/dealers'

import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { DEALER_TYPES } from '../../helpers/constants'
import { DealerSearch } from '../DealerSearch'
import { Form } from '../Form'
import { GeolocationTrigger } from '../GeolocationTrigger'
import { MapCanvas } from '../MapCanvas'
import { MapHeader } from '../MapHeader'
import { MapLocationCard } from '../MapLocationCard'
import PropTypes from 'prop-types'
import StyledMapComposition from './style'
import { TextLink } from '../TextLink'
import { getUrl } from '../../helpers/url'
import { useTranslation } from '../../hooks/useTranslation'

const dealersHasType = (dealer, type) => {
  const {
    acfAttributes: { dealerServiceCenter, dealerShopCenter },
  } = dealer

  if (type === DEALER_TYPES.STORE && dealerShopCenter) return true
  if (type === DEALER_TYPES.WORKSHOP && dealerServiceCenter) return true
  return false
}

const MapComposition = props => {
  const { mapHeaderLink, dealers } = props

  const [state, dispatch] = useContext(AppContext)
  const { dealersFilterString, userPosition, dealerFlag } = state
  const { geometry } = dealersFilterString

  const getTranslation = useTranslation()

  const [selectedType, setSelectedType] = useState(DEALER_TYPES.ALL)

  const handleSelectedDealer = id => {
    dispatch(toggleDealerFlag(!dealerFlag))
    dispatch(setSelectedDealerId(id))
  }

  const filteredDealers = dealers
    .filter(dealer => {
      return selectedType === DEALER_TYPES.ALL
        ? true
        : dealersHasType(dealer, selectedType)
    })
    .filter(dealer => {
      if (
        Object.keys(dealersFilterString).length === 0 ||
        dealersFilterString.length === 0
      )
        return true

      if (typeof dealersFilterString == 'string') {
        const dealerAttr = dealer.acfAttributes

        const searchArr = [
          dealer.title,
          dealerAttr.dealerAddress,
          dealerAttr.dealerCity,
          dealerAttr.dealerCity,
          dealerAttr.dealerCountry,
          dealerAttr.dealerZip,
        ]

        const replaceSpecialChars = {
          'ÃƒÂ¶': 'o',
          'ÃƒÂ¤': 'a',
          'ÃƒÂ¥': 'a',
        }

        return [...searchArr.join(' ').toLowerCase()]
          .map(char => {
            if (replaceSpecialChars[`${char}`]) {
              return replaceSpecialChars[`${char}`]
            } else {
              return char
            }
          })
          .join('')
          .includes(dealersFilterString.toLowerCase())
      }
      return true
    })

  let position = null
  if (userPosition) {
    position = userPosition
  } else if (geometry) {
    position = {
      latitude: geometry.location.lat(),
      longitude: geometry.location.lng(),
    }
  }

  const sortedDealers = position
    ? sortDealersByDistance(filteredDealers, position)
    : sortDealersByRank(filteredDealers)

  return (
    <StyledMapComposition {...props}>
      <MapHeader link={mapHeaderLink} />
      <StyledMapComposition.Row>
        <StyledMapComposition.Aside>
          <StyledMapComposition.AsideHeader>
            <Form.Group>
              <GeolocationTrigger />
            </Form.Group>
            <Form.Group>
              <DealerSearch
                dealers={dealers}
                onSearch={str => {
                  dispatch(setDealerFilterString(str))
                }}
              />
            </Form.Group>
            <Form.Group>
              <ButtonGroup>
                <Button
                  outline
                  small
                  active={selectedType === DEALER_TYPES.ALL}
                  handleClick={() => {
                    setSelectedType(DEALER_TYPES.ALL)
                  }}
                >
                  {getTranslation('allText')}
                </Button>
                <Button
                  outline
                  small
                  active={selectedType === DEALER_TYPES.STORE}
                  handleClick={() => {
                    setSelectedType(DEALER_TYPES.STORE)
                  }}
                >
                  {getTranslation('shopText')}
                </Button>
                <Button
                  outline
                  small
                  active={selectedType === DEALER_TYPES.WORKSHOP}
                  handleClick={() => {
                    setSelectedType(DEALER_TYPES.WORKSHOP)
                  }}
                >
                  {getTranslation('workshopText')}
                </Button>
              </ButtonGroup>
            </Form.Group>
          </StyledMapComposition.AsideHeader>
          <StyledMapComposition.MobileMap>
            <MapCanvas dealers={sortedDealers} />
          </StyledMapComposition.MobileMap>
          <StyledMapComposition.AsideList>
            {sortedDealers.length > 0 ? (
              sortedDealers.map((dealer, index) => {
                const {
                  id,
                  title,
                  slug,
                  uri,
                  acfAttributes: {
                    dealerAddress,
                    // dealerAddress2,
                    dealerCity,
                    dealerListCity,
                    dealerCountry,
                    dealerPhone,
                    dealerServiceCenter,
                    dealerShopCenter,
                    dealerLatitude,
                    dealerLongitude,
                    dealerPartner,
                  },
                  distance,
                } = dealer
                const types = [
                  dealerServiceCenter ? DEALER_TYPES.WORKSHOP : null,
                  dealerShopCenter ? DEALER_TYPES.STORE : null,
                ]
                return (
                  <React.Fragment key={id}>
                    <StyledMapComposition.Button
                      onClick={() => handleSelectedDealer(id)}
                    >
                      <MapLocationCard
                        title={title}
                        types={types}
                        phone={dealerPhone}
                        address={dealerAddress}
                        city={dealerListCity ? dealerListCity : dealerCity}
                        distance={distance}
                        link={getUrl(uri)}
                        priority={dealerPartner}
                      />
                    </StyledMapComposition.Button>
                  </React.Fragment>
                )
              })
            ) : (
              <StyledMapComposition.AsideEmptyMessage>
                {getTranslation('searchText')}
              </StyledMapComposition.AsideEmptyMessage>
            )}
          </StyledMapComposition.AsideList>
          <StyledMapComposition.AsideFooter>
            {mapHeaderLink && (
              <TextLink
                to={getUrl(mapHeaderLink.url)}
                text={mapHeaderLink.title}
                underline
              />
            )}
          </StyledMapComposition.AsideFooter>
        </StyledMapComposition.Aside>
        <StyledMapComposition.Map>
          <MapCanvas dealers={sortedDealers} />
        </StyledMapComposition.Map>
      </StyledMapComposition.Row>
    </StyledMapComposition>
  )
}

MapComposition.propTypes = {
  mapHeaderLink: PropTypes.object,
}

export { MapComposition }
