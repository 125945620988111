import styled from 'styled-components'
import StyledForm from '../Form/style'
import { typography, colors } from '../../styles/utilities/variables'

const StyledDealerSearch = styled.div`
  position: relative;

  ${StyledForm.Input} {
    font-family: ${typography.secondaryFont};
    border: 1px solid ${colors.primaryDarkBlue};
  }
`

export default StyledDealerSearch
