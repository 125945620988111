export const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const randomNumber = (min, max) => {
  return Math.random() * (max - min) + min
}

export const floatToInt = value => {
  return value | 0
}

// * Get distance between 2 locations
// * @param lat1
// * @param lon1
// * @param lat2
// * @param lon2
// * @param unit, 'M' is statute miles (default), 'K' is kilometers, 'N' is nautical miles
// * @returns {number}

export const getDistance = (lat1, lon1, lat2, lon2, unit) => {
  let radlat1 = (Math.PI * lat1) / 180
  let radlat2 = (Math.PI * lat2) / 180
  let theta = lon1 - lon2
  let radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  if (unit === 'K') {
    dist = dist * 1.609344
  }
  if (unit === 'N') {
    dist = dist * 0.8684
  }
  return Math.round(dist * 10) / 10

  // return dist < 50 ? Math.round(dist * 10) / 10 : false
}
