import styled from 'styled-components'
import { radius } from '../../styles/utilities/variables'

import StyledButton from '../Button/style'

const StyledButtonGroup = styled.div`
  display: flex;
  width: 100%;

  ${StyledButton} {
    flex: 1;
    border-radius: 0;
    border-right-width: 0;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    margin: 0;

    span {
      width: 100%;
    }

    &:first-child {
      border-radius: ${radius.medium} 0 0 ${radius.medium};
    }
    &:last-child {
      border-radius: 0 ${radius.medium} ${radius.medium} 0;
      border-right-width: 1px;
    }
  }
`

export default StyledButtonGroup
