import React, { useState, useContext } from 'react'
import {
  AppContext,
  setUserPosition,
  setDealerFilterString,
  setSelectedDealerId,
} from '../../config/context'

import { TEXT_SIZES } from '../../helpers/constants'
import StyledGeolocationTrigger from './style'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { useTranslation } from '../../hooks/useTranslation'

const GeolocationTrigger = props => {
  const [state, dispatch] = useContext(AppContext) // eslint-disable-line

  const getTranslation = useTranslation()
  const { dealersFilterString, selectedDealerId } = state

  const [isRetrieving, setIsRetrieving] = useState(false)
  const [hasTried, setHasTried] = useState(false)
  const [success, setSuccess] = useState(false)
  const [text, setText] = useState(
    getTranslation('findNearestShopOrWorkshopText')
  )

  const getPosition = () => {
    if (navigator.geolocation) {
      setIsRetrieving(true)

      navigator.geolocation.getCurrentPosition(
        position => {
          console.log('positions', position.coords)

          if (dealersFilterString) {
            dispatch(setDealerFilterString(''))
          }

          if (selectedDealerId) {
            dispatch(setSelectedDealerId(false))
          }

          let userInput = document.getElementById('dealerSerach').value

          if (document.getElementById('dealerSerach')) {
            document.getElementById('dealerSerach').value = ''
          }

          setIsRetrieving(false)
          setHasTried(true)
          setSuccess(true)

          dispatch(setUserPosition(position.coords))
        },
        error => {
          console.log('getPosition -> error', error)
          setText(getTranslation('noPositionText'))
          setIsRetrieving(false)
          setHasTried(true)
          setSuccess(false)
        },
        {
          timeout: 10000,
        }
      )
    } else {
      setText(getTranslation('browserSupportText'))
      setIsRetrieving(false)
      setHasTried(true)
      setSuccess(false)
    }
  }

  return (
    <StyledGeolocationTrigger onClick={getPosition}>
      <StyledGeolocationTrigger.Icon>
        {isRetrieving ? (
          <StyledGeolocationTrigger.Spinner>
            <Icon icon={'hourglass_empty'} />
          </StyledGeolocationTrigger.Spinner>
        ) : hasTried ? (
          success ? (
            <Icon icon={'gps_fixed'} />
          ) : (
            <Icon icon={'gps_off'} />
          )
        ) : (
          <Icon icon={'gps_not_fixed'} />
        )}
      </StyledGeolocationTrigger.Icon>
      <Text size={TEXT_SIZES.SMALL}>{text}</Text>
    </StyledGeolocationTrigger>
  )
}

export { GeolocationTrigger }
