import styled from 'styled-components'
import {
  spacing,
  colors,
  radius,
  typography,
} from '../../styles/utilities/variables'

const StyledAutoComplete = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  bottom: -${spacing.xSmall};
  background: ${colors.white};
  border-radius: ${radius.medium};
  padding: ${spacing.base};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
`

StyledAutoComplete.Button = styled.button`
  border: none;
  outline: none;
  background: transparent;
  color: ${colors.primaryDarkBlue};
  padding: ${spacing.xSmall};
  margin-bottom: ${spacing.xxSmall};
  text-align: left;
  font-family: ${typography.secondaryFont};
  line-height: 1.3;

  &:last-child {
    margin-bottom: 0;
  }

  & div {
    display: flex;
  }

  & p {
    margin: 0;

    > span {
      font-weight: bold;
      font-size: ${typography.sizes.base};
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  span {
    color: ${colors.primaryDarkBlue};
    font-size: 13px;
  }

  &:hover {
    background: ${colors.adaptedLightBlueBackground};
  }

  &:active,
  &:focus {
    outline: none;
  }

  background: ${props =>
    props.active ? colors.adaptedLightBlueBackground : 'transparent'};
`

export default StyledAutoComplete
