import React, { useState, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { useClickOutside } from '../../hooks/useClickOutside'
import StyledDealerSearch from './style'
import { AutoComplete } from '../AutoComplete'
import { useTranslation } from '../../hooks/useTranslation'
import GoogleAutoComplete from '../AutoComplete/google-autocomplete.js'
import {
  AppContext,
  setUserPosition,
  setSelectedDealerId,
} from '../../config/context'
import { typography } from '../../styles/utilities/variables'

const DealerSearch = props => {
  const { dealers, onSearch } = props
  const [state, dispatch] = useContext(AppContext)
  const { userPosition, selectedDealerId } = state

  const inputEl = useRef(null)
  const autoCompleteRef = useRef(null)

  const getTranslation = useTranslation()

  const [searchString, setSearchString] = useState('')
  const [showAutoComplete, setShowAutoComplete] = useState(false)
  const [autoCompleteCursor, setAutoCompleteCursor] = useState(null)

  const parseDealerObject = item => {
    const { name, value, city, text } = item
    return {
      name: name,
      value: value,
      city: city,
      text: text,
    }
  }

  let autoCompleteObjects = dealers
    .reduce((acc, dealer) => {
      const {
        title,
        acfAttributes: { dealerAddress, dealerCity, dealerCountry },
      } = dealer

      let dealerFields = [
        parseDealerObject({
          name: title,
          value: 'title',
          city: dealerCity,
          text: (dealerAddress || '').replace(',', '') + ', ' + dealerCity,
        }),
        parseDealerObject({
          name: dealerAddress,
          value: 'adress1',
          city: dealerCity,
          text: dealerCity,
        }),
        parseDealerObject({
          name: dealerCity,
          value: 'city',
          city: dealerCity,
          text: null,
        }),
        parseDealerObject({
          name: dealerCountry,
          value: 'country',
          city: dealerCity,
          text: null,
        }),
      ]

      let fieldsToAdd = dealerFields.filter(field => {
        let dealerThatMatches = acc.filter(accField => {
          if (field.value === 'adress1')
            return field.name === accField.name && field.city === accField.city
          return field.name === accField.name
        })
        return !dealerThatMatches.length
      })

      return [...acc, ...fieldsToAdd]
    }, [])
    .filter(object => object.name !== null)

  const matchingObjects = autoCompleteObjects.filter(obj => {
    let searchStr =
      typeof searchString === 'string' && searchString.length > 0
        ? searchString.toLowerCase()
        : ''
    return Object.values(obj)
      .join(' ')
      .toLowerCase()
      .includes(searchStr)
  })

  const handleKeyDown = e => {
    const userInput = e.target.value

    if (!userInput || userInput <= 1) return

    if (selectedDealerId) {
      dispatch(setSelectedDealerId(false))
    }

    if (e.key === 'Enter') {
      setShowAutoComplete(false)

      if (userPosition) {
        dispatch(setUserPosition(false))
      }

      if (userInput && userInput.length > 0) {
        const service = new window.google.maps.places.AutocompleteService(
          null,
          {}
        )
        service.getPlacePredictions(
          { input: userInput },
          (predictions, status) => {
            if (
              predictions &&
              typeof predictions[0] !== 'undefined' &&
              status === 'OK'
            ) {
              const geocoder = new window.google.maps.Geocoder()

              geocoder.geocode(
                { placeId: predictions[0].place_id },
                (results, status) => {
                  if (
                    results &&
                    typeof results[0] !== 'undefined' &&
                    status === 'OK'
                  ) {
                    const res = results[0]
                    onSearch({ geometry: res.geometry })
                  } else {
                    onSearch(userInput)
                  }
                }
              )
            }
          }
        )
      }
    } else if (e.key === 'ArrowUp') {
      if (autoCompleteCursor === null) {
        setAutoCompleteCursor(0)
      } else if (autoCompleteCursor > 0) {
        setAutoCompleteCursor(autoCompleteCursor - 1)
      }
    } else if (e.key === 'ArrowDown') {
      if (autoCompleteCursor === null) {
        setAutoCompleteCursor(0)
      } else if (autoCompleteCursor < matchingObjects.length - 1) {
        setAutoCompleteCursor(autoCompleteCursor + 1)
      }
    }
  }

  const handlePlaceSelected = place => {
    if (typeof place.geometry === 'undefined') {
      return
    }

    if (selectedDealerId) {
      dispatch(setSelectedDealerId(false))
    }

    if (userPosition) {
      dispatch(setUserPosition(false))
    }
    setShowAutoComplete(true)
    setAutoCompleteCursor(null)
    setShowAutoComplete(false)
    onSearch(place)
  }

  const onAutoCompleteSelect = value => {
    inputEl.current.value = value
    setShowAutoComplete(false)
    setSearchString(value)
    onSearch(value)
    setAutoCompleteCursor(null)
  }

  const onAutoCompleteSoftSelect = value => {
    inputEl.current.value = value
  }

  const autocompleteStyle = {
    width: '100%',
    background: '#FFFFFF',
    padding: '1.2rem 1.2rem',
    fontSize: '1.6rem',
    borderRadius: '0.4rem',
    border: '1px solid #ACBDC4',
    position: 'relative',
    zIndex: '2',
    maxWidth: '350px',
    fontFamily: typography.secondaryFont,
    border: '1px solid #003145',
  }

  useClickOutside(autoCompleteRef, () => {
    setShowAutoComplete(false)
    setAutoCompleteCursor(null)
  })

  return (
    <StyledDealerSearch>
      <GoogleAutoComplete
        style={autocompleteStyle}
        onPlaceSelected={place => {
          handlePlaceSelected(place)
        }}
        placeholder={getTranslation('searchForNameOrPlaceText')}
        name={'dealerSearch'}
        innerRef={inputEl}
        onKeyDown={handleKeyDown}
        inputAutocompleteValue={'off'}
        id='dealerSerach'
      />
      {showAutoComplete && (
        <AutoComplete
          matchingObjects={matchingObjects}
          searchString={searchString}
          autoCompleteCursor={autoCompleteCursor}
          onSelect={value => {
            onAutoCompleteSelect(value)
          }}
          onSoftSelect={value => {
            onAutoCompleteSoftSelect(value)
          }}
          innerRef={autoCompleteRef}
        />
      )}
    </StyledDealerSearch>
  )
}

DealerSearch.propTypes = {
  dealers: PropTypes.array,
  onSearch: PropTypes.func,
}

export { DealerSearch }
