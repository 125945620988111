import styled, { keyframes } from 'styled-components'
import {
  spacing,
  colors,
  typography,
  cssEasings,
} from '../../styles/utilities/variables'
import StyledIcon from '../Icon/style'
import StyledText from '../Text/style'

const StyledGeolocationTrigger = styled.button`
  display: flex;
  align-items: center;

  border: none;
  outline: none;
  background: transparent;
  margin: 0;
  padding: 0;

  &:active,
  &:focus {
    outline: none;
  }

  ${StyledText} {
    margin: 0;
    font-family: ${typography.secondaryFont};
  }
`

StyledGeolocationTrigger.Icon = styled.div`
  position: relative;
  background: ${colors.primaryDarkBlue};
  color: ${colors.secondaryLightBlue};
  border-radius: 50%;
  width: 3.8rem;
  height: 3.8rem;
  margin-right: ${spacing.small};

  ${StyledIcon} {
    font-size: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
    animation-timing-function: ${cssEasings.easeInOutCubic};
  }

  75% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
`

StyledGeolocationTrigger.Spinner = styled.div`
  animation: ${rotate} 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
`

export default StyledGeolocationTrigger
