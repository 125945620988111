import styled, { css } from 'styled-components'
import { spacing, colors, mq } from '../../styles/utilities/variables'
import { headerHeights } from '../../views/Header/style'
import StyledForm from '../Form/style'
import StyledMapLocationCard from '../MapLocationCard/style'
import { Link } from 'gatsby'

const StyledMapComposition = styled.div`
  height: calc(100vh - ${headerHeights.desktop});
  display: flex;
  flex-direction: column;
`

StyledMapComposition.Row = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;

  @media ${mq.mobile} {
    overflow: visible;
  }
`

StyledMapComposition.Aside = styled.aside`
  width: 38rem;
  display: flex;
  flex-direction: column;

  ${StyledForm.Group} {
    margin-bottom: ${spacing.medium};

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${mq.mobile} {
    width: 100%;
  }
`

StyledMapComposition.AsideHeader = styled.div`
  padding: ${spacing.medium};
  border-bottom: 1px solid ${colors.adaptedLightBlue};
`

StyledMapComposition.AsideFooter = styled.div`
  display: none;

  @media ${mq.mobile} {
    display: block;
    padding: ${spacing.medium};
  }
`

StyledMapComposition.AsideList = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;

  & ${StyledMapLocationCard} {
    padding: ${spacing.base} ${spacing.medium};
    border-bottom: 1px solid ${colors.adaptedLightBlue};
  }

  @media ${mq.mobile} {
    overflow: visible;
  }
`

StyledMapComposition.AsideEmptyMessage = styled.p`
  position: absolute;
  text-align: center;
  font-style: italic;
  color: rgba(0, 49, 69, 0.7);
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

StyledMapComposition.Map = styled.div`
  flex: 1;

  @media ${mq.mobile} {
    display: none;
  }
`

StyledMapComposition.MobileMap = styled.div`
  display: none;
  @media ${mq.mobile} {
    display: block;
    width: 100vw;
  }
`

const generalStyles = () => css`
  color: ${colors.black};
  border: none;
  background: transparent;
  padding: 0;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
  }
`

StyledMapComposition.Button = styled.button`
  ${generalStyles}
`

StyledMapComposition.Link = styled(Link)`
  ${generalStyles}
  display: none;
`

export default StyledMapComposition
